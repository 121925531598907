// create an axios instance
// const service = axios.create({
//   baseURL: "http://shequ.mynatapp.cc/api",
// });
import axios from "axios";

// create an axios instance
const service = axios.create({
  baseURL: "https://h5.gcsjkj.com/api",
});
service.postForm = function (url, data, opt) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (typeof value === "object") {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, data[key]);
    }
  });
  return service.post(url, formData, opt);
};

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (document.getElementsByClassName("modal-login-timeout").length) {
      config.cancelToken = new axios.CancelToken((cancel) => {
        cancel();
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    // 请求二进制数据时，不对返回值做处理
    if (response.request.responseType === "blob") {
      return response;
    }
    return { ...response, $response: response };
  },
  (error) => {
    console.log("request error：", error);
    if (axios.isCancel(error)) {
      console.log("request cancel");
    } else {
      let { response } = error;
      return response
        ? { ...response, $response: response }
        : Promise.reject(error);
    }
  }
);

export { service };
